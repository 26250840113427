const Handlebars = require('handlebars');
const ToastrDefaults = {
  closeButton: true,
  closeMethod: 'fadeOut',
  closeDuration: 2000,
  positionClass: 'toast-bottom-right'
}
const moment = require('moment/moment')

// TinyMCE
import tinymce from 'tinymce/tinymce';
import contentStyle from 'tinymce/skins/ui/oxide/content.min.css';

// Return meta values in HTML head
export function metaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  if (element) {
    return element.getAttribute("content")
  } else {
    return ''
  }
}

export function selectAllCheckboxes(elements, checked) {
  elements.forEach((el, i) => {
    el.checked = checked
  })
}

export function showModal(content, modalId = "atalisModal", contentId = "atalisModalContent") {
  let modal = document.getElementById(modalId);
  if (content.length > 0) {
    document.getElementById(contentId).innerHTML = content
  }
  document.body.classList.add("modal-open")
  modal.classList.add("show")
}

export function errorsList(errors) {
  return getTemplateContent("error-list-template", { errors: errors })
}

export function defaultModalContent(content) {
  return getTemplateContent("default-modal-content-template", content)
}

export function getTemplateContent(templateId, content) {
  let source = document.getElementById(templateId).innerHTML;
  let template = Handlebars.compile(source);
  return template(content)
}

export function toastrContent(content) {
  let str = []
  if (Array.isArray(content)) {
    let div;
    for (let cont of content) {
      str.push(cont + "<br />")
    }
    return str.join('')
  } else {
    return content.toString()
  }
}

// Show toastr and reload the page
export function showToastr(title, content, type, options = ToastrDefaults) {
  let cont = toastrContent(content)
  if (type == 'success') {
    toastr.success(cont, title, options)
  } else if (type == 'warning') {
    toastr.warning(cont, title, options)
  } else if (type == 'error') {
    toastr.error(cont, title, options)
  } else {
    toastr.info(cont, title, options)
  }
}

// Show bootbox confirmation
export function bootboxConfirm(confirmationText, yes, no, callback) {
  bootbox.confirm({
    message: confirmationText,
    buttons: {
      confirm: {
        label: yes,
        className: 'btn-success'
      },
      cancel: {
        label: no,
        className: 'btn-danger'
      }
    },
    callback: callback
  });
}

export function showOrHideLoader(wrapper, show) {
  if (show) {
    wrapper.innerHTML = '<div class="loader"></div>'
  } else {
    wrapper.innerHTML = ''
  }
}

export function createLoaderWrapper(target) {
  let loaderWrapper = target.querySelector('.loader-wrapper-div')
  if (!loaderWrapper) {
    loaderWrapper = document.createElement('div')
    loaderWrapper.classList.add('loader-wrapper')
    loaderWrapper.classList.add('loader-wrapper-div')
    target.appendChild(loaderWrapper)
  }
  return loaderWrapper
}

export function disableOrEnableButton(btn, isDisabled) {
  btn.disabled = isDisabled
}

export function initializeSelect2(targetIdentifier = '.select2-dropdown', callbacks = {}, options = {}) {
  let opts = {
    placeholder: select2PlaceHolder
  }

  if (Object.keys(options).length > 0) {
    opts = Object.assign({}, opts, options)
  }

  let $select2 = $(targetIdentifier).select2(opts);

  if (callbacks.hasOwnProperty('select')) {
    $(targetIdentifier).on('select2:select', callbacks.select);
  }
}

export function initializeMultiSelect(targetIdentifier = '.ms-parent', callbacks = {}, options = {}) {
  let opts = {
    width: options.width || '100%'
  }
  let formatAllSelected = ('allSelectedMessage' in options) ? options.allSelectedMessage : 'All selected'
  let formatNoMatchesFound = ('noMatchMessage' in options) ? options.noMatchMessage : 'No matches found'

  let refresh = options.refresh

  if (Object.keys(options).length > 0) {
    opts = Object.assign({}, opts, options)
  }

  if (callbacks.hasOwnProperty('onChange')) {
    $(targetIdentifier).on('change', callbacks.onChange);
  }

  $("div.ms-parent button.ms-choice span:contains(" + options.placeholder + ")").addClass('placeholder');

  opts = Object.assign({}, opts, {
    ellipsis: true,
    formatSelectAll: () => '[ Check all ]',
    formatAllSelected: () => formatAllSelected,
    formatNoMatchesFound: () => formatNoMatchesFound,
    onClick: function(view) {
      // if (options.freezeOnSelect) {
      //   let el = $(event.target.parentNode.parentNode.parentNode.parentNode.parentNode)
      //   el.addClass('block-mouse')
      // } else if (options.fireOnSelect) {
      //   let field = options.fireOnSelect.field
      //   let url = new URL(window.location.href)
      //   let fieldInUrl = url.searchParams.get(field)
      //   if (view.value) {
      //     resetSearchKey(url)
      //     url.searchParams.set(field, view.value);
      //     window.location.replace(url)
      //   } else if (fieldInUrl) {
      //     resetSearchKey(url)
      //     url.searchParams.delete(field);
      //     window.location.replace(url)
      //   }
      // }
    },
    onClose: function() {
      // if (options.fireOnClose) {
      //   let field = options.fireOnClose.field
      //   let dropdown = (field === 'u') ? 'university' : 'department'
      //   let url = new URL(window.location.href)
      //   url.searchParams.delete(`${field}[]`);
      //   $.each($(`.ms-parent.${dropdown}-dropdown li.selected input`), function() {
      //     if (this.value != 'on') {
      //       resetSearchKey(url);
      //       url.searchParams.append(`${field}[]`, this.value);
      //     }
      //   })
      //   window.location.replace(url)
      // }
    }
  })

  if (options.freezeOnLoad) {
    for (let el of $(targetIdentifier)) {
      $(el).addClass('block-mouse')
    }
  }

  if (refresh) {
    $(targetIdentifier).multipleSelect('refresh');
  } else {
    $(targetIdentifier).multipleSelect(opts);
  }
}

export function copyToClipboard(text) {
  let el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  return true
}

export function enableOrDisable($field, enable = true) {
  if (enable) {
    $field.classList.remove('not-clickable')
  } else {
    $field.classList.add('not-clickable')
  }
}

export function formChanged(form) {
  $(form + ' input, ' + form + ' textarea, ' + form + ' select, ' + form + ' iframe').on('change', function(e) {
    if (e.target.dataset.noInputChangeTrack == 'true') {
      return
    }
    isFormDirty = true;
  })
}

export function setHashFragment(fragment) {
  window.location.hash = fragment
}

export function initTinymce(selector = '.tinymce') {
  tinymce.init({
    menubar: false,
    selector: selector,
    skin: false,
    plugins: 'advlist link hr lists code paste visualchars table searchreplace print preview',
    toolbar: `
      bold italic underline link | bullist numlist | alignleft aligncenter alignright alignjustify
      outdent indent | hr paste | visualchars forecolor backcolor | table tableprops | code searchreplace |
      removeformat undo redo`,
    content_style: 'body { font-family: sans-serif; }',
    content_css: '/stylesheets/tinymce.css',
    height: 400,
    default_link_target: '_blank',
    target_list: false,
    init_instance_callback: function(editor) {
      editor.on('Dirty', function(e) {
        $(e.target.targetElm).trigger('change')
      });
    }
  });
}

export function toggleSlideArrow($el) {
  $el.on('click', function(event) {
    $(event.target).toggleClass('slide-up')
    $(event.target).toggleClass('slide-down')
  })
}

export function parseDate(date, format) {
  return moment(date, format)
}

// Uses momentjs function
export function addToDate(date, format, count, type) {
  let dateObj = parseDate(date, format)
  let newDate = dateObj.add(count, type);

  return newDate.format(format)
}

export function resetSearchKey(url) {
    let searchKey = $('#cms-filter-hidden-key').val();
    let searchField = $('#cms-filter-hidden-key').attr('name') || 'k';
    if (searchKey) {
      url.searchParams.set(searchField, searchKey);
    } else {
      url.searchParams.delete(searchField);
    }
}
