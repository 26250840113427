import { Controller } from "stimulus"
import { showModal, selectAllCheckboxes, errorsList, showToastr, bootboxConfirm, initializeSelect2, initializeMultiSelect, getTemplateContent, formChanged, setHashFragment, initTinymce, toggleSlideArrow, resetSearchKey } from "../helpers/utils"
import { getJsonRequest } from "../helpers/request"
import Rails from 'rails-ujs'

const filterGijgoConf = {
    format: global.gijgoDefaultFormat,
    weekStartDay: 1,
    uiLibrary: 'bootstrap4',
    locale: $('body').data('locale'),
    close: function(e, type) {
        // let field = e.target.getAttribute('data-field')
        // let value = e.target.value;
        // let url = new URL(window.location.href);
        // resetSearchKey(url);
        // if (value == null) {
        //     url.searchParams.delete(field);
        // } else {
        //     url.searchParams.set(field, value);
        // }
        // window.location.replace(url)
    }
}

export default class extends Controller {
    static targets = [
        "selectAll", "programsIndexCheckbox", "editSelected",
        "noMultipleEditTemplate", "newProgramsBtn", "newProgramsFormTemplate", "selectedIds",
        "activateSelected", "deactivateSelected", "duplicateSelected", "deleteSelected", "programsTypeDropdown",
        'programForm', 'profileDropdown', 'versionTitle', 'contentTitle', 'programFilter',
        'programListTab'
    ]

    initialize() {}

    connect() {
        this.initializeFormDropdowns()
        this.initializeFilterDropdowns()
        this.initializeCocoonCallbacks()
        this.initializeFormElementCallbacks()
        this.trackFormChange()
        this.showTab()
        this.initSlideEvent()
        this.initializeDatepickers()
    }

    detectCheckBoxSelected(event){
        event.stopPropagation()
        var selectedElement = this.element.children[0].id
        if(selectedElement === 'active'){
            document.getElementById('inactive').checked = false
        }else{
            document.getElementById('active').checked = false
        }
    }

    initializeFilterDropdowns() {
        if (this.hasProgramFilterTarget) {
            initializeMultiSelect('.profile-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'p' } })
            initializeMultiSelect('.university-dropdown', { onChange: this.onUniversitySelect }, { minimumCountSelected: 3, dropWidth: 126, placeholder: select2PlaceHolder, fireOnClose: { field: 'u' } })
            initializeMultiSelect('.department-dropdown', {}, { minimumCountSelected: 3, filter: true, placeholder: select2PlaceHolder, fireOnClose: { field: 'd' } })
            initializeMultiSelect('.area-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'a' } })
            initializeMultiSelect('.type-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 't' } })
            initializeMultiSelect('.funding-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'f' } })

            initializeMultiSelect('.year-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'y' } })
            initializeMultiSelect('.eligibility-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'e' } })
            initializeMultiSelect('.ambit-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'b' } })
        }
    }

    initializeFormDropdowns() {
        // Multi selection dropdowns
        initializeMultiSelect('.program-profile-dropdown', {}, { minimumCountSelected: 3, dropWidth: 174, placeholder: selectText })
        initializeMultiSelect('.customer-department-dropdown', {}, { minimumCountSelected: 3, filter: true, placeholder: select2PlaceHolder })
        initializeMultiSelect('.program-area-dropdown', {}, { minimumCountSelected: 3, dropWidth: 200, placeholder: selectText })
        initializeMultiSelect('.program-funding-dropdown', {}, { minimumCountSelected: 3, dropWidth: 200, placeholder: selectText })
        initializeMultiSelect('.program-type-dropdown', {}, { minimumCountSelected: 3, dropWidth: 235, placeholder: selectText })
            // Single selection dropdowns
        initializeMultiSelect('.program-year-dropdown', {}, { single: true, placeholder: selectText })
        initializeMultiSelect('.program-eligibility-dropdown', {}, { single: true, placeholder: selectText })
        initializeMultiSelect('.program-ambit-dropdown', {}, { single: true, placeholder: selectText })
        initializeMultiSelect('.program-version-dropdown', {}, { single: true, placeholder: selectText, allSelectedMessage: 'Current version selected', noMatchMessage: noVersionMatchMessage })
        initializeMultiSelect('.customer-news-type-dropdown', {}, { single: true, placeholder: selectText })
        initializeMultiSelect('.customer-ambit-dropdown', {}, { single: true, placeholder: selectText })
        initializeMultiSelect('.customer-university-dropdown', {}, { single: true, placeholder: selectText, freezeOnLoad: true })
        $("div.ms-parent button.ms-choice span:contains(" + selectText + ")").addClass('placeholder');
    }

    initializeFormElementCallbacks() {
        $('select.program-version-dropdown').on('change', function() {
            let href = new URL(window.location.href)
            href.searchParams.set('version_id', $(this).val())
            window.location.href = href
        });
    }

    initializeCocoonCallbacks() {
        let that = this
        $('#program-customers-wrapper')
            .on('cocoon:after-insert', function(e, added_customer) {
                $('#programs-general-add-customer-btn').hide()

                let uniqueId = Math.random().toString(36).substr(2, 9) + '-' + Date.now() + '-' + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
                let newsTypeId = $(added_customer).find('.customer-news-type-dropdown')[0].id
                let departmentId = $(added_customer).find('.customer-department-dropdown')[0].id
                let ambitId = $(added_customer).find('.customer-ambit-dropdown')[0].id
                let universityId = $(added_customer).find('.customer-university-dropdown')[0].id
                let $department = $(added_customer).find('.customer-department-dropdown')

                $(added_customer).find('.customer-attributes').attr('id', 'customer-' + uniqueId)
                $('#' + universityId).attr('data-customerblockid', uniqueId)
                $(added_customer).find('.slide-arrow').attr('data-target', '#customer-' + uniqueId)
                $department.addClass('customer-department-dropdown-' + uniqueId)

                that.removeSelectedCustomers($('#' + universityId))
                    // Multiple
                initializeMultiSelect('#' + departmentId, {}, { minimumCountSelected: 3, filter: true, placeholder: select2PlaceHolder })
                    // Single
                initializeMultiSelect('#' + universityId, { onChange: that.onUniversitySelect }, { single: true, placeholder: selectText, allSelectedMessage: '', freezeOnSelect: true })
                initializeMultiSelect('#' + newsTypeId, {}, { single: true, placeholder: selectText })
                initializeMultiSelect('#' + ambitId, {}, { single: true, placeholder: selectText })

                toggleSlideArrow($(added_customer).find('.slide-arrow'))

                $("div.ms-parent button.ms-choice span:contains(" + selectText + ")").addClass('placeholder');

                $('select.customer-university-dropdown').on('change', function(e) {
                    if (universitiesWithDepartments.includes(parseInt(e.target.value))) {
                        $('#add-customer-scoped-info-btn').click()

                        let $addedBlock = $("#program-customer-scoped-info-wrapper .nested-fields").last()
                        $addedBlock.find('.customer-scoped-info-label').text($(e.target).find('option:selected').html())
                        $addedBlock.find('.customer_scoped_info_university_id').val(e.target.value)
                        $addedBlock.find('.remove-customer-info-btn').addClass('remove-customer-info-btn-' + e.target.value)

                        initTinymce('#' + $addedBlock.find('.customer_info').attr('id'))
                        that.trackFormChange()
                    } else {
                        $(e.target).parent().parent().find('div.program-customer-department-wrapper').hide()
                    }
                    $('#programs-general-add-customer-btn').show()
                });
            })

        $('#program-customers-wrapper')
            .on('cocoon:after-remove', function(e, removed_customer) {
                let $university = $(removed_customer).find('select.customer-university-dropdown')
                    // let el = $(removed_customer).parent().hasClass('saved-customer-details') ? '.saved-customer-details-wrapper' : removed_customer
                let el = $(removed_customer).parent().hasClass('saved-customer-details') ? $(removed_customer).parent() : $(removed_customer)
                $('.remove-customer-info-btn-' + $university.val()).click()
                el.hide()
                $('#programs-general-add-customer-btn').show()
            });
    }

    removeSelectedCustomers(target) {
        let selectedValues = []
        let $univDropDown = target[0]
        $("select.customer-university-dropdown").each(function(index, el) {
            if (el != $univDropDown) {
                $('#' + $univDropDown.id + ' option[value="' + el.value + '"]').remove()
            }
        })
    }

    selectAllRecords(event) {
        let target = event.target;
        selectAllCheckboxes(this.programsIndexCheckboxTargets, target.checked)
        this.enableOrDisablePrograms()
        this.showOrHideTopMenu()
    }

    selectRecord(event) {
        this.enableOrDisablePrograms()
        this.showOrHideTopMenu()
    }

    enableOrDisablePrograms() {
        if (this.programsIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
            this.deleteSelectedTarget.classList.remove('disabled-programs')
            this.editSelectedTarget.classList.remove('disabled-programs')
        } else {
            this.deleteSelectedTarget.classList.add('disabled-programs')
            this.editSelectedTarget.classList.add('disabled-programs')
        }
    }

    showOrHideTopMenu() {
        if (this.programsIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
            $(event.target).parents('.table-wrapper').find('.top-menu').removeClass('hidden')
        } else {
            $(event.target).parents('.table-wrapper').find('.top-menu').addClass('hidden')
        }
    }

    /* BULK ACTION */

    fetchSelectedIds() {
        let that = this
        let selectedIds = []
        let selectedCount = 0
        that.programsIndexCheckboxTargets.forEach((el, i) => {
            if (el.checked) {
                selectedIds.push(el.getAttribute('value'))
                selectedCount = selectedCount + 1
            }
        })
        if (selectedCount >= 1) {
            that.selectedIdsTarget.value = JSON.stringify(selectedIds)
        }

        return selectedIds
    }

    showBootboxConfirm(confirmationText, url) {
        let that = this
        let selectedIds = that.fetchSelectedIds()
        if (selectedIds && selectedIds.length > 0) {
            bootboxConfirm(confirmationText, yesText, noText, function(result) {
                if (result) {
                    that.selectedIdsTarget.parentNode.setAttribute('action', url)
                    Rails.fire(that.selectedIdsTarget.parentNode, 'submit');
                }
            })
        }
    }

    editPrograms() {
        let url = this.activateSelectedTarget.dataset.url
        let selectedIds = this.fetchSelectedIds()
        let selectedCount = selectedIds.length
        if (selectedCount == 0) { return }
        if (selectedCount == 1) {
            window.location.replace(programsBasePath + '/' + selectedIds[0] + '/edit')
        } else {
            toastr.error(noMultipleEditText)
        }
    }

    activatePrograms() {
        let url = this.activateSelectedTarget.dataset.url
        this.showBootboxConfirm(activateConfirmationText, url)
    }

    deactivateOrActivateProgram() {
        $(event.target).parents('table').find('input[type=checkbox]').attr('checked', false)
        $(event.target).parents('tr').find('input[type=checkbox]').attr('checked', 'checked')
        if ($(event.target).hasClass('hide')) {
            this.activatePrograms()
        } else {
            this.deactivatePrograms()
        }
    }

    deactivatePrograms() {
        let url = this.deactivateSelectedTarget.dataset.url
        this.showBootboxConfirm(deactivateConfirmationText, url)
    }

    duplicatePrograms() {
        let url = this.duplicateSelectedTarget.dataset.url
        this.showBootboxConfirm(duplicateConfirmationText, url)
    }

    deletePrograms() {
        let url = this.deleteSelectedTarget.dataset.url
        this.showBootboxConfirm(deleteConfirmationText, url)
    }

    batchActionSuccess(event) {
        let [data, status, xhr] = event.detail;
        if (data.success) {
            window.location.reload()
        } else {
            this.batchActionError(event)
        }
    }

    batchActionError(event) {
        let [data, status, xhr] = event.detail;
        let el = event.target.querySelector('.error-list-container')
        if (!data.success) {
            if (el) {
                el.innerHTML = errorsList(data.errors)
            } else {
                showToastr(errorTitle, errorsList(data.errors), 'error')
            }
        }
    }

    deleteDocumentSuccess(event) {
        let [data, status, xhr] = event.detail;

        if (data.success) {
            event.target.parentElement.remove();
            event.stopPropagation();
        }
    }

    onProgramsTypeSelect(event) {
        let eventPanel = document.getElementById('event-settings-panel')
        if (event.target.value == eventTypeId) {
            eventPanel.classList.remove('not-clickable')
        } else {
            eventPanel.classList.add('not-clickable')
            document.getElementById('start_date-datepicker').value = ''
            document.getElementById('start_time-timepicker').value = ''
            document.getElementById('end_date-datepicker').value = ''
            document.getElementById('end_time-timepicker').value = ''
            document.getElementById('event-settings-toggle').checked = false
            document.getElementById('start-date-time-wrapper').classList.add('not-clickable')
            document.getElementById('end-date-time-wrapper').classList.add('not-clickable')
        }
    }

    onUniversitySelect(e) {
        let data = $(e.target).data();
        let departmentCls;
        if (data.context == 'programForm') {
            let blockId = this.getAttribute('data-customerblockid')
            departmentCls = 'select.customer-department-dropdown-' + blockId
        } else if (data.context == 'programFilter') {
            departmentCls = 'select.department-filter-dropdown'
        }
        let that = this;
        getJsonRequest(departmentsPath + '?page=1&per_page=1000&university_id=' + $(e.target).val()).then(response => {
            if (response.success) {
                let options = getTemplateContent('selectOptions', { options: response.departments, emptyOption: false })
                that.form.querySelector(departmentCls).innerHTML = options
                initializeMultiSelect(departmentCls, {}, { refresh: true, filter: true, placeholder: select2PlaceHolder })
            }
        })
    }

    toggleEventSettings(event) {
        this.changeDateTimeWrapperStatus(event.target.checked)
    }

    changeDateTimeWrapperStatus(activate) {
        if (activate) {
            document.getElementById('start-date-time-wrapper').classList.remove('not-clickable')
            document.getElementById('end-date-time-wrapper').classList.remove('not-clickable')
        } else {
            this.resetDateTimePickers()
            document.getElementById('start-date-time-wrapper').classList.add('not-clickable')
            document.getElementById('end-date-time-wrapper').classList.add('not-clickable')
        }
    }

    resetDateTimePickers() {
        document.getElementById('start_date-datepicker').value = ''
        document.getElementById('start_time-timepicker').value = ''
        document.getElementById('end_date-datepicker').value = ''
        document.getElementById('end_time-timepicker').value = ''
    }

    copyToVersionTitle(e) {
        this.versionTitleTarget.value = e.target.value
    }

    updateCustomerAttributes(event) {
        if (event.target.dataset.programVersionId != undefined) {
            event.target.form.submit()
        }
    }

    /* BULK ACTION */

    newPrograms() {
        window.location.replace(programsBasePath + '/new')
    }

    trackFormChange() {
        formChanged('#program-form')
    }

    showTab() {
        if (this.hasProgramFormTarget && fragmentHistory) {
            setHashFragment(localStorage.getItem('programs-form-hash-fragment'))
        } else if (this.hasProgramFormTarget && !fragmentHistory) {
            localStorage.setItem('programs-form-hash-fragment', '')
        }

        if (this.hasProgramListTabTarget && fragmentHistory) {
            setHashFragment(localStorage.getItem('programs-list-hash-fragment'))
        }
    }

    initSlideEvent() {
        if (this.hasProgramFormTarget) {
            toggleSlideArrow($('.slide-arrow'))
        }
    }

    radioButtonFilter(event) {
        let field = event.target.getAttribute('data-field')
        let value = event.target.getAttribute('data-value')

        let url = new URL(window.location.href);
        let fieldInUrl = url.searchParams.get(field);
        resetSearchKey(url);
        if (fieldInUrl === value) {
            url.searchParams.delete(field);
        } else {
            url.searchParams.set(field, value);
        }
        window.location.replace(url)
    }

    initializeDatepickers() {
        let flag = $(".program_updated_from-datepicker").length;
        if (flag) {
            $(".program_updated_from-datepicker").datepicker(filterGijgoConf);
            $(".program_updated_until-datepicker").datepicker(filterGijgoConf);
            $(".call_opened_from-datepicker").datepicker(filterGijgoConf);
            $(".call_opened_to-datepicker").datepicker(filterGijgoConf);
            $(".call_closed_from-datepicker").datepicker(filterGijgoConf);
            $(".call_closed_to-datepicker").datepicker(filterGijgoConf);
        }
    }

    checkPublished() {
        if (!$.forceSaveProgram) {
            let e = event
            $.programFormTarget = e.target

            e.preventDefault()

            if ($.programFormTarget.dataset.currentPublishedVersion == 'true') {
                bootboxConfirm(versionPublishedWarning, yesText, noText, function(result) {
                    if (result) {
                        $.forceSaveProgram = true
                        $($.programFormTarget).click()
                    }
                })
            } else {
                $.forceSaveProgram = true
                $($.programFormTarget).click()
            }
        }
    }

    checkEmptyFields(event) {
        if (!$.forceSaveProgram) {
            let e = event;
            $.programFormTarget = e.target;
            e.preventDefault();

            let allEmptyFields = [];

            const defaultValues = [selectText, select2PlaceHolder].filter(Boolean);

            const generalFields = [
                { selector: '.program-year-dropdown .ms-choice span', label: year },
                { selector: '.program-profile-dropdown .ms-choice span', label: profile },
                { selector: '.program-type-dropdown .ms-choice span', label: type },
                { selector: '.program-area-dropdown .ms-choice span', label: area },
                { selector: '.program-eligibility-dropdown .ms-choice span', label: eligibility },
                { selector: '.program-ambit-dropdown .ms-choice span', label: ambit },
                { selector: '.program-funding-dropdown .ms-choice span', label: funding }
            ];

            generalFields.forEach(field => {
                let element = document.querySelector(field.selector);

                const isEmpty = !element ||
                    (element.textContent && (!element.textContent.trim() || defaultValues.includes(element.textContent.trim()))) ||
                    (element.value && (!element.value.trim() || defaultValues.includes(element.value.trim())));

                if (isEmpty) {
                    allEmptyFields.push(field.label);
                }
            });

            const customerBlocks = document.querySelectorAll('.nested-fields');

            customerBlocks.forEach((block, index) => {
                const universitySelect = block.querySelector('.customer-university-dropdown');
                const selectedOption = universitySelect ? universitySelect.options[universitySelect.selectedIndex] : null;
                const selectedUniversityCode = selectedOption && selectedOption.getAttribute('data-code') ? selectedOption.getAttribute('data-code') : '';

                if (!selectedUniversityCode) {
                    return;
                }

                const labels = document.querySelectorAll('.customer-scoped-info-label');
                let matchingBlock = null;
                let customerInfoValue = 'Not existing';
                for (const labelElement of labels) {
                    const labelContent = labelElement.textContent.trim();

                    if (labelContent === selectedUniversityCode) {
                        matchingBlock = labelElement.closest('.nested-fields');
                        const customerInfoElement = matchingBlock.querySelector('.tinymce.customer_info');
                        customerInfoValue = customerInfoElement ? customerInfoElement.innerHTML.trim() : '';

                        break;
                    }
                }

                const requiredFields = [
                    ...(selectedUniversityCode !== universityCodeCipf && selectedUniversityCode !== universityCodeGh && block.querySelector('.program-customer-department-wrapper:not(.hide) .ms-choice span') ?
                        [{ selector: '.customer-department-dropdown .ms-choice span', label: `${selectedUniversityCode} Ofi` }] : []),
                    ...(selectedUniversityCode !== universityCodeGb ?
                        [{ selector: '.customer-news-type-dropdown .ms-choice span', label: `${selectedUniversityCode} ${customerNews}` }] : []),
                    { selector: '.customer-ambit-dropdown .ms-choice span', label: `${selectedUniversityCode} ${ambit}` },
                    ...(selectedUniversityCode === universityCodeGb ?
                        [{ selector: 'input.form-control[data-target="programs.customerFund"]', label: `${selectedUniversityCode} ${funding}` }] : []),
                    ...(selectedUniversityCode !== universityCodeAta && customerInfoValue === '' ?
                        [{ selector: '.tinymce.customer_info', label: `${selectedUniversityCode} ${customerInfo}` }] : [])
                ];

                requiredFields.forEach(field => {
                    let element;

                    if (field.selector) {
                        element = block.querySelector(field.selector);
                    }

                    const isEmpty = !element ||
                        (!element.value && element.tagName === 'INPUT') || // Check .value directly for input elements
                        (!element.textContent && element.tagName !== 'INPUT') ||
                        (element.tagName === 'INPUT' ? !element.value.trim() : !element.textContent.trim()) ||
                        defaultValues.includes(element.tagName === 'INPUT' ? element.value.trim() : element.textContent.trim());

                    if (isEmpty) {
                        allEmptyFields.push(field.label);
                    }
                });

            });

            if (allEmptyFields.length > 0) {
                const allEmptyFieldsList = allEmptyFields.join(', ');
                bootboxConfirm(`${allEmptyFieldsList} ${empty_fields_warning}`, yesText, noText, function(result) {
                    if (result) {
                        $.forceSaveProgram = true;
                        $($.programFormTarget).click();
                    }
                });
            } else {
                $.forceSaveProgram = true;
                $($.programFormTarget).click();
            }
        }
    }
}
